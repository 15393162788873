<template>
  <div>
    <div class="flex justify-center">
      <div v-if="isShowProfile" class="space-y-3 px-4" style="max-width: 375px">
        <input type="file" class="hidden" ref="updateImgProfile" @change="selectImage" accept="image/*">
        <div v-if="isShowAvatar" class="text-center" @click="$refs.updateImgProfile.click()">
          <vs-avatar v-if="!profile.avatar_thumbnail" size="70px" class="text-6xl avatar-img" :color="getColor(profile.name)" :text="getInitial(profile.name)" />
          <vs-avatar v-if="profile.avatar_thumbnail" size="70px" class="text-6xl avatar-img" :src="profile.avatar_thumbnail" />
        </div>
        <div class="modify-img flex mt-2 justify-center">
          <vs-button color="secondary" type="border" class="p-1 px-4 "
            @click="$refs.updateImgProfile.click()">
            <div class="flex items-center pt-1">
              <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
              {{$t('Upload Avatar')}}
            </div>
          </vs-button>
          <vs-button class="ml-2 btn-icon" color="secondary" type="border" icon="delete"
            @click="deleteAvatar()"></vs-button>
        </div>
        <div class="">
          <vs-input v-filter-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="name"
            type="text"
            :label="$t('Name')"
            :placeholder="$t('Name')"
            class="w-full"
            v-model="profile.name"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('name')) }}</span>
        </div>
        <div>
          <vs-input v-filter-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="company"
            type="text"
            :label="$t('Company')"
            :placeholder="$t('Company')"
            class="w-full"
            v-model="profile.company"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('company')) }}</span>
        </div>
        <div>
          <vs-input v-filter-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="job_title"
            type="text"
            :label="$t('Job Title')"
            :placeholder="$t('Job Title')"
            class="w-full"
            v-model="profile.job_position"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('job_title')) }}</span>
        </div>
        <div>
          <vs-input v-filter-input-email
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            name="email"
            type="email"
            :label="$t('Email')"
            :placeholder="$t('Email')"
            class="w-full"
            v-model="profile.email"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('email')) }}</span>
        </div>
        <div class=" flex justify-between">
          <div>
            <label class="text-sm" style="padding-left: 6px; color: #5F5F5F;">{{ $t('Language') }}</label>
            <vs-select
              name="language-settings-user"
              style="max-width: 164px"
              v-model="profile.language"
              >
              <vs-select-item
              v-for="item in languageOptions"
                :key="item.value"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
          </div>
          <div>
              <vs-input v-filter-input
                data-vv-validate-on="blur"
                name="country"
                type="text"
                :label="$t('Country')"
                :placeholder="$t('Country')"
                style="max-width: 164px"
                v-model="profile.country"
              />
              <span class="text-danger text-sm">{{ $t(errors.first('country')) }}</span>
            </div>
        </div>
        <div>
          <label class="text-sm" style="padding-left: 6px; color: #5F5F5F;">{{ $t('Phone Number') }}</label>
          <VuePhoneNumberInput
            :translations="phoneTranslations"
            class="input-number"
            :default-country-code="defaultCountryCode"
            :preferred-countries="['NO', 'US', 'GB']"
            @update="onUpdatePhone"
            v-model="profile.mobile_phone"
            :no-validator-state="true"
            />
        </div>
        <!-- extended -->
        <div style="max-width: 375px" class="flex justify-between">
          <div>
            <vs-input v-filter-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="checked_in_at"
              type="text"
              :label="$t('Checked In')"
              :placeholder="$t('Checked In')"
              style="max-width: 164px"
              disabled
              :value="dateFormat(profile.checked_in_at)"
            />
            <span class="text-danger text-sm">{{ $t(errors.first('checked_in_at')) }}</span>
          </div>

          <div>
            <vs-input v-filter-input
              data-vv-validate-on="blur"
              name="room"
              type="text"
              :label="$t('Room')"
              :placeholder="$t('Room')"
              style="max-width: 164px"
              disabled
              v-model="profile.room"
            />
            <span class="text-danger text-sm">{{ $t(errors.first('room')) }}</span>
          </div>
        </div>

        <div class="flex  justify-between">
          <div>
            <vs-input v-filter-input
              data-vv-validate-on="blur"
              name="table"
              type="text"
              :label="$t('Table')"
              :placeholder="$t('Table')"
              style="max-width: 160px"
              disabled
              v-model="profile.table"
            />
            <span class="text-danger text-sm">{{ $t(errors.first('table')) }}</span>
          </div>

          <div>
            <vs-input v-filter-input
              data-vv-validate-on="blur"
              name="seat"
              type="text"
              :label="$t('Seat')"
              :placeholder="$t('Seat')"
              disabled
              style="max-width: 164px"
              v-model="profile.seat"
            />
            <span class="text-danger text-sm">{{ $t(errors.first('seat')) }}</span>
          </div>
        </div>

        <div class="mt-4">
          <vs-button
            @click="showQr()"
            color="secondary"
            type="filled"
            class="submit-button mr-2 my-1 text-black w-full"
            >
            {{ !isShowQr ? $t('Show QR Code') : $t('Hide QR Code') }}
          </vs-button>
        </div>

        <div>
          <vs-checkbox
            v-model="profile.agree"
            v-tooltip="{
              content: ModalTooltip(),
              loadingContent: 'Please wait...',
            }">
            {{ $t('I agree to use my profile details while using Koblatil') }}
          </vs-checkbox>
        </div>
        <div class="flex justify-between" style="padding-bottom: 5em;">
          <vs-button
            :class="{ 'is-loading': isSaving , }"
            :disabled="isSaving "
            @click="closeEdit()"
            color="#797979"
            type="filled"
            class="submit-button"
            >
            {{ $t('Cancel') }}
          </vs-button>
          <div class="flex">
            <vs-button
              :class="{ 'is-loading': isSaving }"
              :disabled="isSaving || (profile && !profile.agree)"
              @click="updateProfile()"
              color="primary"
              type="filled"
              class="submit-button"
              >
              {{ $t('Save') }}
            </vs-button>
          </div>
        </div>
        <modal
          class="modal-profie"
          size="small-medium"
          :hide-footer="true"
          :hasScroll="true"
          :Modal-close="true"
          :isEnableKeyUp="false"
          @close="isShowQr = false"
          v-if="isShowQr">
          <div slot="header">
            <h5 class="modal-title">{{ $t('QR Code') }}</h5>
          </div>
          <div slot="body">
            <div>
              <profile-q-r :profile="profile" v-if="isShowQr" />
            </div>
          </div>
        </modal>
        <modal
          class="modal-profie"
          size="medium"
          :hide-footer="true"
          :hasScroll="true"
          :Modal-close="true"
          :isEnableKeyUp="false"
          @close="closeModalCropper"
          v-if="isShowModalCropper">
          <div slot="header">
            <h5 class="modal-title">{{ $t('Set Profile Picture') }}</h5>
          </div>
          <div slot="body">
            <div>
              <ImageCropper :imageCropper="imageCropper" @cropImage="cropImage" @close="close"/>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  getFirstCharacter, generateColorByAlphabet, getAxiosErrorMessage, duplicateVar, dateFormat,
} from '@/lib/helper';
import ImageCropper from '@/components/ImageCropper.vue';
import fileApi from '@/api/file';
import { mapGetters } from 'vuex';
import participantApi from '@/api/participant';
import Modal from '@/components/Modal.vue';
import ProfileQR from '@/components/events/profile/ProfileQR.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  components: {
    ImageCropper,
    Modal,
    ProfileQR,
    VuePhoneNumberInput,
  },
  props: {
  },
  data() {
    return {
      languageOptions: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],
      isLocalProfile: false,
      localName: '',
      isShowModalCropper: false,
      isShowAvatar: true,
      imageCropper: null,
      profile: {
        id: null,
        event_id: null,
        name: '',
        status: '',
        code: '',
        email: '',
        language: '',
        company: '',
        roles: '',
        created_at: '',
        updated_at: '',
        is_seat_confirm: null,
        notes: null,
        is_owner: null,
        avatar: '',
        avatar_thumbnail: '',
        checked_in_at: null,
        checked_out_at: null,
        mobile_phone: '',
        job_position: '',
        country: '',
        room: '',
        table: '',
        seat: '',
        user_type: '',
      },
      isShowProfile: false,
      isSaving: false,
      isShowQr: false,
      resultsPhone: {},
      phoneTranslations: {
        countrySelectorLabel: this.$t('Code Country'),
        countrySelectorError: this.$t('Choose one country'),
        phoneNumberLabel: this.$t('Phone Number'),
        example: this.$t('Example:'),
      },
      defaultCountryCode: 'NO', // default to NO initially
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  methods: {
    onUpdatePhone(payload) {
      this.resultsPhone = payload;
    },
    dateFormat(date) {
      if (date) return dateFormat(date, 'DD. MM. YYYY HH:mm');
      return '-';
    },
    showQr() {
      this.isShowQr = !this.isShowQr;
    },
    ModalTooltip() {
      const headline = this.$t('Under the EU General Data Protection Regulation, we need your approval for our use of personal information (e.g. your name and email address) you may provide as we communicate:');
      const msg1 = this.$t('1. We\'ll store your personal information so that we can pick up the conversation if we talk later.');
      const msg2 = this.$t('2. We may send you emails to follow up on our discussion here.');
      const msg3 = this.$t('3. We may send you emails about upcoming events.');
      const final = `<div><p style="margin-bottom: 10px">${headline}</p><p>${msg1}</p><p>${msg2}</p><p>${msg3}</p></div>`;
      return final;
    },
    getProfile() {
      this.profile = duplicateVar(this.user);
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    // onFetchLocalProfile() {
    //   // this.$emit('useLocalProfile');
    //   const localProfile = JSON.parse(localStorage.getItem('userInfo'));
    //   const accessToken = localProfile.access_token;
    //   localStorage.setItem('access_token', accessToken);
    // },
    onGetFirstWord(str) {
      return str && str.length > 0 ? str[0] : '';
    },
    selectImage(input) {
      this.imageCropper = input;
      this.isShowModalCropper = true;
    },
    closeModalCropper() {
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
    },
    close() {
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
    },
    cropImage(image) {
      this.$vs.loading();
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
      if (image) {
        this.isUploading = true;
        const file = image;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.profile.avatar = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.profile.avatar = url;
          this.profile.avatar_thumbnail = url;
          this.isShowAvatar = false;
          setTimeout(() => {
            this.isShowAvatar = true;
          }, 500);
          this.isUploading = false;
          this.$vs.loading.close();
          this.imageCropper = null;
          this.$refs.updateImgProfile.value = null;
          this.isShowModalCropper = false;
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
          this.$vs.loading.close();
          this.imageCropper = null;
          this.$refs.updateImgProfile.value = null;
          this.isShowModalCropper = false;
        };
        fileApi.upload(formData, callback, errorCallback);
      }
    },
    deleteAvatar() {
      this.profile.avatar = '';
      this.profile.avatar_thumbnail = '';
      this.isShowAvatar = false;
      setTimeout(() => {
        this.isShowAvatar = true;
      }, 500);
      this.isUploading = false;
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
    },
    closeEdit() {
      this.$router.push(`/c/${this.$route.query.event}`);
    },
    updateProfile() {
      const callback = (response) => {
        const item = response.data;
        this.profile.name = item.name;
        this.profile.email = item.email;
        this.profile.language = item.language;
        this.profile.company = item.company;
        this.profile.agree = item.agree;
        this.profile.avatar = item.avatar;
        this.profile.avatar_thumbnail = item.avatar_thumbnail;
        this.profile.notes = item.notes;
        this.profile.room = item.room;
        this.profile.table = item.table;
        this.profile.seat = item.seat;
        this.profile.country = item.country;
        this.profile.mobile_phone = item.mobile_phone;
        this.profile.job_position = item.job_position;
        this.profile.access_token = localStorage.getItem('access_token');
        localStorage.setItem('userInfo', JSON.stringify(this.profile));
        this.$store.dispatch('updateUserInfo', this.profile);
        this.$store.dispatch('setUser', item);
        this.closeEdit();
      };
      const errCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: 'Profile',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      const params = {
        name: this.profile.name,
        email: this.profile.email,
        company: this.profile.company,
        language: this.profile.language,
        avatar: this.profile.avatar,
        avatar_thumbnail: this.profile.avatar_thumbnail,
        room: this.profile.room,
        seat: this.profile.seat,
        table: this.profile.table,
        country: this.profile.country,
        mobile_phone: this.resultsPhone.countryCallingCode + this.resultsPhone.nationalNumber,
        job_position: this.profile.job_position,
      };
      const id = this.user.event_id;
      participantApi.saveProfile(id, params, callback, errCallback);
    },
    async getCountryCode() {
      try {
        const response = await axios.get('https://ip2c.org/s');
        const data = response.data.split(';');
        if (data[0] === '1') {
          this.defaultCountryCode = data[1]; // data[1] contains the country code
        } else {
          console.error('Unable to determine country code.');
        }
      } catch (error) {
        console.error('Error fetching country code:', error);
      }
    },
  },
  mounted() {
    this.$vs.loading();
    setTimeout(() => {
      this.getProfile();
    }, 1500);
    setTimeout(() => {
      this.isShowProfile = true;
      this.$vs.loading.close();
    }, 1700);
  },
  created() {
    this.getCountryCode();
  },
  destroyed() {
  },
};
</script>
